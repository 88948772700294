






















import { Component, Model, Prop, Vue } from 'vue-property-decorator';

import { defaultsDeep } from 'lodash-es';
import { QuillOptionsStatic } from 'quill';
import QuillJS from '@/lib/quill';
import { ImageService } from '@/lib/services';

import {
  buildTagMap,
  getDefaultTranslateData,
  Mapping,
  translateTags,
} from '@/lib/tagtranslator';

function quillTagsHandler(this: any, tag: string) {
  const quill = this.quill as QuillJS;
  // TODO(joost 08-10-2021): this used a type assertion, which we're in the process
  // of banning. is this a good equivalent action?
  const pos = quill.getSelection()?.index ?? 0;
  quill.insertText(pos, `{${tag.replace(/-/g, '')}}`);
}

function quillImageHandler(this: any) {
  const input = document.createElement('input');

  input.setAttribute('type', 'file');
  input.setAttribute('accept', 'image/*');
  input.click();

  input.onchange = async () => {
    const file = input.files?.[0];
    if (file === undefined) {
      return;
    }

    // Save current cursor state
    const range = this.quill.getSelection(true);

    const url = await ImageService.uploadInline(file);
    // Insert uploaded image
    this.quill.insertEmbed(range.index, 'image', url);
  };
}

function quillPreviewHandler(this: any) {
  const quill = this.quill as QuillJS;
  // Yea... I want to piggyback the event bus. They don't really want that
  const eventEmitter = (quill as any).emitter;
  eventEmitter.emit('preview');
}

const DEFAULT_CONFIG = (withPreview: boolean) => ({
  modules: {
    toolbar: {
      container: [
        [{ size: ['small', false, 'large', 'huge'] }],
        ['bold', 'italic', 'underline', 'strike'],
        ['blockquote', 'code-block'],
        [{ color: [] }, { background: [] }],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['image', 'link'],
        [{ align: [] }],
        [
          {
            tags: [
              'company-name',
              'company-contact',
              'sender',
              'invoice-number',
              'invoice-date',
              'invoice-due-date',
              'invoice-amount',
              'current-month',
              'previous-month',
              'next-month',
              'current-year',
              'previous-year',
              'next-year',
              'payment-link',
              'view-invoice',
              'accept-button',
              'quotation-number',
              'pay-widget',
              'remind-date',
            ],
          },
        ],
        withPreview ? ['preview'] : undefined,
      ],
      handlers: {
        tags: quillTagsHandler,
        image: quillImageHandler,
        preview: quillPreviewHandler,
      },
    },
  },
  theme: 'snow',
});

@Component
export default class Quill extends Vue {
  @Model('change') content: any;

  @Prop({ type: Array, default: () => [] })
  formats: any[];

  @Prop({ type: Boolean, default: true })
  useHtml: boolean;

  @Prop({
    type: Object,
    default: () => ({}),
  })
  config: QuillOptionsStatic;

  @Prop({
    default: null,
  })
  containers: Record<string, unknown>[] | null;

  @Prop({ type: Boolean, default: false })
  allowPreviewTranslate: boolean;

  editor: QuillJS;
  openModal: boolean = false;

  tagMap: Mapping;

  mounted(): void {
    const config: QuillOptionsStatic = defaultsDeep(
      this.config,
      DEFAULT_CONFIG(this.allowPreviewTranslate),
    );
    if (this.containers !== null && config.modules) {
      config.modules.toolbar.container = this.containers;
    }
    this.editor = new QuillJS(this.$refs['quill'] as Element, config);
    this.i18n();

    if (this.content && this.content !== '') {
      if (this.useHtml) {
        this.editor.pasteHTML(this.content);
      } else {
        this.editor.setText(this.content);
      }
    }

    this.editor.on('text-change', () => {
      if (this.useHtml) {
        this.$emit('change', this.editor.root.innerHTML);
      } else {
        this.$emit('change', this.editor.getText(0));
      }
    });

    // @ts-ignore As we are manually sending these events
    this.editor.on('preview', () => {
      this.openModal = true;
    });

    this.$on('set-html', (html) => {
      this.editor.root.innerHTML = html;
    });

    this.$on('set-text', (text) => {
      this.editor.setText(text);
    });

    this.generateMapping();
  }

  private i18n(): void {
    const translations = this.$i18n.t('email.tags');

    // Set individual items
    const tags = this.$el.querySelectorAll<HTMLElement>(
      '.ql-tags .ql-picker-item',
    );
    tags.forEach(
      (e) =>
        (e.textContent = translations['items'][e.dataset.value] || 'Unknown'),
    );

    // Set the 'global' name
    const label = this.$el.querySelector<HTMLElement>(
      '.ql-tags .ql-picker-label',
    )!;
    label.innerHTML = translations['header'] + label.innerHTML;
  }

  private async generateMapping(): Promise<void> {
    this.tagMap = buildTagMap(await getDefaultTranslateData());
  }

  previewContent(): string {
    return translateTags(this.content.replace(/\n/g, '<br>'), this.tagMap);
  }
}
