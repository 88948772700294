import { split as smsSplit } from 'twilio-split-sms';
import Quill from 'quill';
import { DEFAULT_I18N } from '@/plugins/i18n';

const SUBSTITUTE_TAGS = {
  paymentlink: 43,
  betaallink: 43,
};

interface CounterOptions {
  container: string;
}

class SmsCounter {
  private quill: Quill;
  private container: Element;

  constructor(quill: Quill, options: CounterOptions) {
    this.quill = quill;
    const el = document.querySelector(options.container);
    if (el === null) {
      throw new Error(`Container '${options.container}' not found`);
    }
    this.container = el;
    quill.on('text-change', this.update.bind(this));
    // Account for initial contents
    this.update();
  }

  transform(text: string): string {
    for (const key in SUBSTITUTE_TAGS) {
      const replacement = '-'.repeat(SUBSTITUTE_TAGS[key]);
      text = text.replace(new RegExp(key, 'gi'), replacement);
    }
    return text;
  }

  update(): void {
    const text = this.transform(this.quill.getText());
    const result = smsSplit(text);
    const renderData = {
      charCount: result.length,
      charTotal: result.length + result.remainingInPart,
      characterSet: result.characterSet,
    };
    const segmentCount = result.parts.length;

    this.container.textContent = DEFAULT_I18N.tc(
      'messages.smsCounter',
      segmentCount,
      renderData,
    );
  }
}

export default SmsCounter;
