import Quill from 'quill';
import SmsCounter from './smscounter';

const font = Quill.import('formats/font');
font.whitelist = ['roboto', 'input'];

const icons = Quill.import('ui/icons');
icons['preview'] =
  '<span class="material-icons md-quill-icon">remove_red_eye</span>';

Quill.register(font, true);
Quill.register('modules/smscounter', SmsCounter);

export default Quill;
